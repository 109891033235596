<template>
  <div>
    <section class="d-none d-sm-none d-md-block d-lg-block d-xl-block">
      <nav_bar />
      <b-container fluid>
        <b-row style="margin-top: 8%;">
          <b-col md="3" lg="3" xl="3">
            <div class="left">
              <div class="buttons">
                <b-button @click="$router.push('/main_dashboard')"
                  >Kembali</b-button
                >
              </div>
              <div class="title">Daftar Fitur</div>
              <div class="list" v-for="item in list" :key="item.idx">
                <div class="judul" @click="getData(item.id)">
                  {{ item.judulFitur }}
                </div>
              </div>
            </div>
          </b-col>
          <b-col md="9" lg="9" xl="9">
            <b-overlay :show="busy" rounded="sm">
              <div class="right">
                <div class="judul">{{ detail.judulFitur }}</div>
                <div class="isi" v-html="detail.detailFitur"></div>
              </div>
            </b-overlay>
          </b-col>
        </b-row>
      </b-container>
    </section>
    <section class="d-sm-block d-md-none d-lg-none d-xl-none">
      <b-container>
        <b-row>
          <b-col cols="12" sm="12">
            <div
              style="
                width: 100%;
                height: 100vh;
                display: flex;
                justify-content: center;
                align-items: center;
              "
            >
              <h4 class="text-center">
                <strong>Does not support mobile version</strong>
              </h4>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </section>
  </div>
</template>

<script>
import nav_bar from "@/components/nav_bar.vue";
import axios from "@/ip_config.js";

export default {
  components: {
    nav_bar,
  },

  data() {
    return {
      showDetail: false,
      list: [],
      detail: [],
      busy: false,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData(id) {
      let vm = this;
      vm.busy = true;
      if (vm.$route.params.id == 0 && id == undefined) {
        let get = await axios.get("fitur/list", {
          headers: {
            token: localStorage.getItem("token"),
          },
        });
        if (get.data.message == "sukses") {
          vm.list = get.data.data;
          vm.busy = false;
        }
      } else if (id != undefined || vm.$route.params.id != 0) {
        vm.detail = [];
        if (id != undefined) {
          vm.$router.push("/fitur/" + id);
        }
        let fetch = await axios.get("fitur/list", {
          headers: {
            token: localStorage.getItem("token"),
          },
        });
        if (fetch.data.message == "sukses") {
          vm.list = fetch.data.data;
          let fromId = await axios.get(
            "fitur/listById/" + vm.$route.params.id,
            {
              headers: {
                token: localStorage.getItem("token"),
              },
            }
          );
          if (fromId.data.message == "sukses") {
            vm.detail = fromId.data.data[0];
            vm.busy = false;
          }
        }
      }
    },
  },
};
</script>

<style scoped>
.left {
  border-right: 3px solid #327ff2;
  height: 85vh;
  position: fixed;
  overflow: scroll;
}
.left .buttons {
  padding: 2% 3%;
}
.left .buttons .btn {
  background-color: #ffffff;
  color: #327ff2;
  border: 1px solid #327ff2;
}
.left .title {
  font-size: 23px;
  padding: 1% 3%;
  font-weight: 600;
  color: #327ff2;
}
.left .list {
  padding: 3%;
  text-transform: capitalize;
  cursor: pointer;
  font-weight: 700;
  border-bottom: 1px solid #ebebeb;
}
.right {
  min-height: 85vh;
}
.right .judul {
  font-size: 30px;
  font-weight: 600;
  text-transform: capitalize;
  color: #327ff2;
}
.right .isi {
  margin-top: 2%;
}
</style>
